import * as React from "react"
import * as style from "../styles/about.module.css"
import Layout from "../components/layout"
import Seo from "../components/seo"
import GlobalContext from "../context/optionContext";
import LinkIcon from '@mui/icons-material/Link';

function AboutPage() {
    return (
        <Layout>
            <Seo title="Plasmapper About" />
            <PageContent />
        </Layout>
    )
}

function CustomLinkIcon() {
    return (<LinkIcon sx={{ transform: "rotate(-30deg)", "vertical-align": "middle", "margin-left": "5px" }} />)
}

function PageContent(props) {
    const { theme, language } = React.useContext(GlobalContext);
    return (
        <>
            <div style={{ height: "60px" }}></div>
            <div style={{ ...theme }}>
                <div className={style.titleText}>{language.ABOUT.title}</div>
                {/* <div className={style.headerText}>History</div> */}
                {/* <div className={style.bodyText}>{language.ABOUT.history}</div> */}
                <p className={style.bodyText}>
                    PlasMapper 3.0 is a web server that allows users to generate, edit, annotate and      interactively visualize publication quality plasmid maps. It is the successor to PlasMapper 2.0 and offers many features found only in commercial plasmid mapping/editing packages. PlasMapper 3.0 allows users to paste or upload plasmid sequences as input or to upload existing plasmid maps from its large database of {'>'} 2000 pre-annotated plasmids (PlasMapDB). This database can be searched by plasmid names, sequence features, restriction sites, preferred host organism, and sequence length. PlasMapper 3.0 also supports the annotation of new or never-before-seen plasmids using its own feature database (FeatureDB) that contains common promoters, terminators, regulatory sequences, replication origins, selectable markers and other features found in most cloning vectors and plasmids. PlasMapper 3.0 has several interactive text editors/viewers that allow users to select and view plasmid regions, insert genes, modify restriction sites or perform codon optimization. The graphics for PlasMapper 3.0 have also been substantially upgraded over what was in PlasMapper 2.0. Specifically, PlasMapper 3.0 now offers an interactive, full-color plasmid viewer/editor that allows users to zoom, rotate, re-color, linearize, circularize, edit annotated features and modify the image or labels to improve the esthetic qualities of their plasmid map and textual displays. All the plasmid images and textual displays are downloadable in multiple high-resolution formats.
                </p>
                {/* <a className={style.linkText} href={language.ABOUT.history_link} target="_blank" rel="noopener noreferrer">PlasMapper 2.0 Publication<CustomLinkIcon /></a> */}
                <div className={style.bodyText}>
                    {/* <h5 style={{ color: "red", fontWeight: 'bold', fontSize: '1.5em', display: 'inline' }}>Credits: </h5> */}
                    <p>
                        <h5 style={{ color: "red", fontWeight: 'bold', fontSize: '1.2em', display: 'inline-block' }}>Credits:</h5> PlasMapper 3.0 was conceived, designed, developed, tested and supported by Leah Ren, Jacques Leong-Sit, Sukanta Saha, Jason R. Grant, Paul Stothard, Upasana Singh, Abby Kropilenicki, Eponine Oler, Harrison Peters, Vasuk Gautam and David S. Wishart.
                    </p>
                    <p>
                        <h5 style={{ color: "red", fontWeight: 'bold', fontSize: '1.2em', display: 'inline-block' }}>Citation:</h5>      Wishart, D. S., Ren, L., Leong-Sit, J., Saha, S., Grant, J. R., Stothard, P., Singh, U., Kropielnicki, A., Oler, E., Peters, H., Gautam, V. (2023), PlasMapper 3.0 - A Web Server for Generating, Editing, Annotating and Visualizing Publication Quality Plasmid Maps, Nucleic Acids Research, https://doi.org/10.1093/NAR/GKAD276
                    </p>
                    <p>
                        <h5 style={{ color: "red", fontWeight: 'bold', fontSize: '1.2em', display: 'inline-block' }}>Source Code:</h5> Please access the PlasMapper source code and instructions for installation <a href="https://bitbucket.org/wishartlab/plasmapper3/src/master/">here (BitBucket)</a>.
                    </p>
                </div>
                {/* <div className={style.headerText}>Architecture</div>
                <div className={style.bodyText}>{language.ABOUT.architecture}</div>
                <div className={style.headerText}>Credits</div>
                <div className={style.bodyText}>{language.ABOUT.credits1}<a class={style.link} href={"https://mui.com/"} target="_blank" rel="noopener noreferrer">{"Material UI"}<CustomLinkIcon /></a></div>
                <div className={style.bodyText}>{language.ABOUT.credits2}<a class={style.link} href={"https://js.cgview.ca/"} target="_blank" rel="noopener noreferrer">{"CGView.js"}<CustomLinkIcon /></a></div>
                <div className={style.bodyText}>{language.ABOUT.credits3}</div> */}

            </div>
        </>
    );
}

export default AboutPage
